.terms-container {
    position: relative; /* Required for z-index */
    transform-style: preserve-3d;
    perspective: 1000px;
}

.terms-container form {
    transform: rotateY(10deg);
}

.terms-list {
    transform: translateZ(40px);
    opacity: 0.9; /* Adjust opacity as needed */
    background-color: rgba(255, 255, 255, 0.8); /* Adjust background color and opacity */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow for depth */
    position: relative;
    z-index: 1; /* Ensure it's above the background */
}

/* Additional styles for aesthetics */
.bg-color {
    background-color: #517296; /* Blue background color */
    color: #fff; /* Text color */
    padding: 10px;
    border-radius: 5px;
}

.terms-list ul {
    list-style-type: none;
    padding: 0;
}

.terms-list ul li {
    margin-bottom: 10px;
}

/* Checkbox styling */
.form-check-input {
    margin-right: 10px;
}

/* Button styling */
.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #0056b3;
}

/* Checkbox styling */
.form-check-input {
    margin-right: 10px; /* Adjust spacing */
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* For older versions of Safari */
    -moz-appearance: none; /* For older versions of Firefox */
    width: 20px; /* Set width */
    height: 20px; /* Set height */
    border: 2px solid #007bff; /* Set border */
    border-radius: 4px; /* Round corners */
    cursor: pointer; /* Show pointer cursor on hover */
}

.form-check-input:checked {
    background-color: #007bff; /* Change background color when checked */
}

.form-check-input:focus {
    outline: none; /* Remove focus outline */
}

/* Checkbox label styling */
.form-check-label {
    font-size: 16px; /* Adjust font size */
    cursor: pointer; /* Show pointer cursor on hover */
}
.password-container {
    position: relative;
    width: 100%;
  }
  
  .form-control {
    width: 100%;
    padding-right: 40px; /* Space for the icon */
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust this value based on your design */
    transform: translateY(-50%);
    cursor: pointer;
    color: #555; /* You can adjust the color */
  }
  