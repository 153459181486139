/* body{
    background:linear-gradient(to left, rgb(77, 75, 224), white);
    margin:0;
    padding:0;
    box-sizing:border-box;
    color:white;
  } */

.containerlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.form-box {
  width: 380px;
  height: 450px;
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.5;
  border-radius: 2%;
}

.header-form {
  margin-bottom: 15px;
}

.message {
  display: flex;
  justify-content: space-between;
}

.social {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.social i {
  font-size: 35px;
}

.social i:hover {
  color: red;
  cursor: pointer;
}

.login-section {
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  background: #f8fafb;
  padding: 30px 15px;
}

.login-section .main-logo img {
  width: 400px;
}

.login-section .right-side-login-content .top-text h4 {
  font-size: 2rem;
  font-weight: 500;
  color: black;
  margin-bottom: 30px;
}

.login-section .right-side-login-content form .form-label {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.login-section .right-side-login-content form .form-control {
  background: #edf2f5;
  font-size: 16px;
  font-weight: 500;
  color: black;
  border: none;
  padding: 12px 15px;
  border-radius: 8px;
}
.login-section .right-side-login-content form .form-control-divs {
  background: #edf2f5;
  font-size: 16px;
  font-weight: 500;
  color: black;
  border: none;
  padding: 12px 15px;
  border-radius: 8px;
  /* display:flex; */
  justify-content: space-between;
}
.form-group input {
  padding-right: 30px; /* Add padding to prevent text from being overlapped by the icon */
}

.form-group i {
  font-size: 16px;
  color: #333; /* Adjust icon color if necessary */
}

.login-section .right-side-login-content form .form-control-div {
  /* background-color: #edf2f5; */
  font-size: 16px;
  font-weight: 500;
  color: black;
  border: none;
  /* padding: 12px 15px; */
  border-radius: 8px;
  display:"flex";
  align-items: center;
  justify-content: space-between;
}

.login-section .right-side-login-content form .form-control:focus {
  box-shadow: none;
}

.login-section .right-side-login-content form .login-btn {
  font-size: 16px;
  color: white;
  border: none;
  background: #6c63ff;
  padding: 10px 20px;
  border-radius: 8px;
}

.login-section .right-side-login-content form .form-check .form-check-input {
  background-color: #e6e6e6;
  width: 20px;
  height: 20px;
  border: none;
}

.login-section .right-side-login-content form .form-check .form-check-input:focus {
  box-shadow: none;
}

.login-section .right-side-login-content form .form-check .form-check-input:checked[type=checkbox] {
  background-color: #6c63ff !important;
}

.login-section .right-side-login-content form .form-check .forgot-link {
  color: #888;
  text-decoration: underline;
}

/* Media query */

@media (max-width:575px) {
  .login-section {
    padding: 20px 10px;
  }

  .login-section .right-side-login-content .top-text h4 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
}